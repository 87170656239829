@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Page Template
// ==================================================
.page-template {
  // Template Content
  .templateContent {
    display: block;
    width: 100%;
    max-width: 960px;
    margin: 100px auto 0;

    @include mixin.sp {
      width: calc(100% - 60px);
      margin-top: 50px;
    }

    h2 {
      font-size: 2.2rem;
      line-height: calc(32 / 22);
      padding-bottom: 8px;
      border-bottom: 2px solid #d9d9d9;
      margin: 60px 0 40px;
      font-weight: 500;

      @include mixin.sp {
        font-size: 1.8rem;
        line-height: calc(22 / 15);
        padding-bottom: 6px;
        margin: 40px 0 20px;
      }
    }

    h3 {
      font-size: 2.4rem;
      line-height: calc(38 / 24);
      margin: 60px 0 24px;
      font-weight: 500;

      @include mixin.sp {
        font-size: 1.7rem;
        line-height: calc(25 / 17);
        margin: 40px 0 22px;
      }
    }

    h4 {
      font-size: 2rem;
      line-height: calc(26 / 20);
      font-weight: 500;
      margin: 40px 0 20px;

      @include mixin.sp {
        font-size: 1.5rem;
        line-height: calc(22 / 15);
        margin: 25px 0 20px;
      }
    }

    p {
      font-size: 1.8rem;
      line-height: calc(38 / 18);
      margin: 18px 0 0;

      @include mixin.sp {
        font-size: 1.4rem;
        line-height: calc(26 / 14);
        margin: 14px 0 0;
      }
    }

    a {
      color: #0085cf;
      text-decoration: underline;

      @include mixin.hover {
        text-decoration: none;
      }

      &[target="_blank"] {
        &::before {
          content: "";
          display: inline-block;
          width: 15px;
          height: auto;
          aspect-ratio: 1/1;
          background-image: url(../img/common/ico_link_blank.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100% auto;
          transform: translateY(1px);
          margin-right: 12px;

          @include mixin.sp {
            width: 14px;
            margin-right: 8px;
          }
        }
      }
    }

    ol {
      margin: 24px 0;
      padding: 0 0 0 3.6em;

      @include mixin.sp {
        padding-left: 1.6em;
      }

      li {
        text-indent: 0.2em;
        margin: 10px 0 0;

        @include mixin.sp {
          margin-top: 7px;
        }

        &::marker {
          font-weight: 600;
          color: #949494;
          font-size: 1.8rem;
          line-height: calc(26 / 18);

          @include mixin.sp {
            font-size: 1.6rem;
            line-height: calc(26 / 14);
          }
        }

        p {
          margin-top: 10px;
          line-height: calc(32 / 18);

          @include mixin.sp {
            margin-top: 7px;
            line-height: calc(22 / 14);
          }
        }
      }
    }

    ul {
      margin: 24px 0;
      padding: 0 0 0 4em;

      @include mixin.sp {
        padding-left: 1.8em;
      }

      li {
        text-indent: -0.2em;
        margin: 10px 0 0;

        @include mixin.sp {
          margin-top: 5px;
        }

        &::marker {
          font-weight: 600;
          color: #949494;
          font-size: 2.4rem;
          line-height: calc(26 / 24);
        }

        p {
          line-height: calc(32 / 18);
          margin-top: 10px;

          @include mixin.sp {
            line-height: calc(22 / 14);
            margin-top: 5px;
          }
        }
      }
    }

    // WordPress 『Gutenberg』
    .wp-block-columns {
      gap: 40px;
      margin: 40px auto 0;

      @include mixin.sp {
        flex-direction: column;
        gap: 20px;
      }

      .wp-block-column {
        width: calc(50% - 20px);

        @include mixin.sp {
          width: 100%;
        }

        :first-child {
          margin-top: 0;
        }
      }

      & + .wp-block-columns {
        margin-top: 40px;

        @include mixin.sp {
          margin-top: 20px;
        }
      }
    }
    .wp-block-image {
      margin: 40px 0 0;

      @include mixin.sp {
        margin-top: 20px;
      }
    }
    .wp-block-quote {
      display: block;
      width: 100%;
      margin: 40px 0 0;
      padding: 30px;
      background-color: #f2f2f2;

      @include mixin.sp {
        margin-top: 20px;
        padding: 20px;
      }

      & > :first-child {
        margin-top: 0;
      }

      .wp-block-heading {
        font-size: 2rem;
        font-weight: 500;
        line-height: calc(29 / 20);

        @include mixin.sp {
          font-size: 1.5rem;
          line-height: calc(20 / 15);
        }
      }

      p {
        font-size: 1.8rem;
        line-height: calc(34 / 18);
        margin: 12px 0 0;

        @include mixin.sp {
          font-size: 1.4rem;
          line-height: calc(24 / 14);
        }
      }

      cite {
        display: block;
        font-size: 1.8rem;
        line-height: calc(34 / 18);
        margin: 12px 0 0;
        font-style: normal;

        @include mixin.sp {
          font-size: 1.4rem;
          line-height: calc(24 / 14);
        }
      }
    }
  }
}
