@charset "UTF-8";

// ==================================================
//  Media Query
// ==================================================
@mixin pc {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin sp {
  @media screen and (max-width: 767px) {
    @content;
  }
}

// ==================================================
//  Hover（PC）
// ==================================================
@mixin hover {
  @include pc {
    &:hover {
      @content;
    }
  }
}
@mixin hoverOpacity60 {
  @include pc {
    transition: opacity 0.3s;
  }

  @include hover {
    opacity: 0.6;
  }
}

// ==================================================
//  Clearfix
// ==================================================
@mixin clearfix {
  &::after {
    content: "";
    clear: both;
    display: block;
  }
}

// ==================================================
//  Font-montserrat
// ==================================================
@mixin font-montserrat {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
